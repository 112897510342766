export const configProd = {
  apiKey: 'AIzaSyAW7sjePg-fCieO4KKZ-Q-PEigyKRLWtsw',
  authDomain: 'ubunifu-prod.firebaseapp.com',
  databaseURL: 'https://ubunifu-prod.firebaseio.com',
  projectId: 'ubunifu-prod',
  storageBucket: 'ubunifu-prod.appspot.com',
  messagingSenderId: '1047072302481',
  appId: '1:1047072302481:web:eed987ca7cbf196b10a630',
  measurementId: 'G-1C7FDG0B0S',
  FUNCTIONS_URL: 'https://asia-northeast1-ubunifu-prod.cloudfunctions.net/'
}

export const configDev = {
  apiKey: 'AIzaSyDL1pAf0gC-60po_sQm0rbAFMCZt8qo4zQ',
  authDomain: 'ubunifu-dev-29783.firebaseapp.com',
  databaseURL: 'https://ubunifu-dev-29783.firebaseio.com',
  projectId: 'ubunifu-dev-29783',
  storageBucket: 'ubunifu-dev-29783.appspot.com',
  messagingSenderId: '444107028009',
  appId: '1:444107028009:web:9c3e586c97aa0c44ad8e38',
  measurementId: 'G-L6B3CP2JXY',
  FUNCTIONS_URL: 'https://asia-northeast1-ubunifu-dev-29783.cloudfunctions.net/'
}

export default {
  namespaced: true,
  state () {
    return {
      version: '0.1.0',
      loading: false,
      windowSize: {}
    }
  },
  getters: {
    version (state) {
      return state.version
    },
    loading (state) {
      return state.loading
    },
    windowSize (state) {
      return state.windowSize
    }
  },
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    },
    setWindowSize (state, payload) {
      state.windowSize = payload
    }
  },
  actions: {
    setLoading ({ commit }, payload) {
      commit('setLoading', payload)
    },
    setWindowSize ({ commit }, payload) {
      commit('setWindowSize', payload)
    }
  }
}

<template>
  <v-app v-resize="onResize">
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      windowSize: 'shared/windowSize'
    })
  },
  mounted () {
    this.onResize()
  },
  methods: {
    onResize () {
      var windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.$store.dispatch('shared/setWindowSize', windowSize)
    }
  }
}
</script>

<style scoped>
.v-application {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>

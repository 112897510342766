<template>
  <!-- <div>
    <core-toolbar />
    <core-view :style="'height: ' + String(windowSize.y - 88 - 88) + 'px;'" />
    <core-footer />
  </div> -->
  <v-layout justify-center row wrap align-center :style="'height: ' + String(windowSize.y) + 'px; overflow: hidden;'">
    <v-col cols="12" class="pa-0" align="center">
      <!-- <core-toolbar /> -->
      <core-view :style="'height: ' + String(windowSize.y - 60) + 'px;'" />
      <core-footer />
      <!-- <span class="display-2 font-weight-bold primary--text pa-0 ma-0">
        クラスジャパン
        <ruby class="display-1 font-weight-bold "><rb>小中学園</rb><rp>（</rp><rt>しょうちゅうがくえん</rt><rp>）</rp></ruby>
      </span><br> -->
    </v-col>
  </v-layout>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      windowSize: 'shared/windowSize'
    })
  },
  components: {
    CoreFooter: () => import('@/components/core/Footer'),
    // CoreToolbar: () => import('@/components/core/Toolbar'),
    CoreView: () => import('@/components/core/View')
  },
  watch: {
    windowSize () {
      // console.log(this.windowSize)
    }
  }
}
</script>

<style scoped>
.container::-webkit-scrollbar {
    display: none;
}
</style>

import Vue from 'vue'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.min.css'
import vuetify from './plugins/vuetify'
import VueI18n from 'vue-i18n'
import VueAnalytics from 'vue-analytics'
import { configProd, configDev } from './firebase/config'

Vue.use(VueI18n)
Vue.config.productionTip = false

const language = (
  (
    window.navigator.languages && window.navigator.languages[0]
  ) || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage || 'ja'
)
const locale = language.substr(0, 2) === 'ja' ? 'ja' : language
const messages = require('./plugins/message.json')

const i18n = new VueI18n({
  locale,
  messages
})

const firebase = require('firebase/app')
switch (location.hostname) {
  case 'ubunifu-prod.web.app':
    firebase.initializeApp(configProd)
    Vue.prototype.$FUNCTIONS_URL = configProd.FUNCTIONS_URL
    Vue.use(VueAnalytics, {
      id: 'UA-79278311-5',
      router
    })
    break
  case 'ubunifu-prod.firebaseapp.com':
    firebase.initializeApp(configProd)
    Vue.prototype.$FUNCTIONS_URL = configProd.FUNCTIONS_URL
    Vue.use(VueAnalytics, {
      id: 'UA-79278311-5',
      router
    })
    break
  case 'ubunifu.co.jp':
    firebase.initializeApp(configProd)
    Vue.prototype.$FUNCTIONS_URL = configProd.FUNCTIONS_URL
    Vue.use(VueAnalytics, {
      id: 'UA-79278311-5',
      router
    })
    break
  default:
    firebase.initializeApp(configDev)
    Vue.prototype.$FUNCTIONS_URL = configDev.FUNCTIONS_URL
    break
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
